import React from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import Profile from '../pages/Profile/Profile';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const ProfileLayout = () => {
  return (
    <div className="flex min-h-screen overflow-hidden">
      <div className="fixed h-full">
        <Sidebar />
      </div>
      <div className="flex-1 flex flex-col ml-64">
        <Navbar />
        <div className="flex-grow">
          <Profile />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ProfileLayout;
